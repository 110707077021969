@import '../variables.scss';

.well-done {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 24px 0 0;
  min-width: 280px;

  .top-text {
    text-align: justify;
  }

  .availability-list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .availability-item {
      display: flex;
      justify-content: space-between;

      .route-dates {
        display: flex;
        flex-direction: column;
      }

      .btns {
        display: flex;
        gap: 16px;
      }
    }
  }

  .add-btn-wrap {
    display: flex;
    justify-content: center;
  }

  .cancel-confirmation {
    // We get the styles from CancelConfirmation.scss
  }
}

@media (max-width: $dt) {
  .well-done {
    margin-top: 0;

    .availability-list {
      .availability-item {
        flex-direction: column;

        .route-dates {
          align-items: center;
        }

        .btns {
          justify-content: space-around;
        }
      }
    }
  }
}
