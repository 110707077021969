@import '../variables.scss';

.modal-yes-no {
  .modal-content-wrapper {
    background-color: black;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 32px;

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: $dt) {
  .modal-yes-no {
    .modal-content-wrapper {
      padding: 32px 16px;
    }
  }
}
