@import '../variables.scss';

#market {
  display: flex;
  flex-direction: column;
  min-height: 1440px;
  position: relative;
}

@media (max-width: $dt) {
  #market {
    margin-top: 24px;

    .back-btn {
      margin-top: -24px;
    }
  }
}
