@import '../variables.scss';

.bubble {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 200px;
  visibility: hidden;
  opacity: 0;
  transition: 300ms;

  &.left {
    right: calc(100% + 16px);
  }

  &.right {
    left: calc(100% + 16px);
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .text {
    color: black;
  }

  .arrow {
    position: absolute;
    top: calc(50% - 8px);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;

    &.right {
      left: -8px;
      border-right: 8px solid white;
    }

    &.left {
      right: -8px;
      border-left: 8px solid white;
    }
  }
}

@media (max-width: $dt) {
  .bubble {
    width: 160px;
  }
}
