@import './variables.scss';

:root {
  --primary-color: #3584e4;
}

@font-face {
  font-family: 'Sen';
  src: url('./assets/fonts/Sen-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Sen';
  src: url('./assets/fonts/Sen-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Sen';
  src: url('./assets/fonts/Sen-Regular.ttf');
  font-weight: 400;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #4d4d4d;
  color: whitesmoke;
  font-family: 'Sen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h2 {
  font-size: 40px;
  font-weight: 700;
}

h3 {
  font-size: 36px;
  font-weight: 400;
}

h4 {
  font-size: 28px;
  font-weight: 400;
}

h5 {
  font-size: 20px;
  font-weight: 400;
}

h6 {
  font-size: 18px;
  font-weight: 700;
}

button {
  font-size: 18px;
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  transition: 200ms;
  cursor: pointer;

  &:hover {
    &:not(:disabled) {
      outline: 2px solid rgba(255, 255, 255, 0.8);
      outline-offset: 2px;
    }
  }

  &:disabled {
    background-color: gray;
    cursor: unset;
  }
}

a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 700;
}

a,
input,
textarea,
select,
button {
  font-family: 'Sen';
  letter-spacing: 1px;
}

label {
  font-size: 16px;
  color: whitesmoke;
}

textarea {
  resize: none;
}

input,
textarea {
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  width: 100%;
  font-size: 18px;
  caret-color: var(--primary-color);
  padding: 8px;
  background-color: black;
  color: whitesmoke;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
}

input[type='radio'],
input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

select {
  font-size: 18px;
  padding: 0 4px;
  background-color: black;
  color: whitesmoke;
  border: none;

  &:focus-visible {
    outline: none;
  }
}

@media (max-width: $dt) {
  body,
  input,
  textarea,
  select {
    font-size: 16px;
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  label {
    font-size: 14px;
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: 16px;
    height: 16px;
  }

  button {
    font-size: 16px;
    padding: 12px 24px;
  }
}
