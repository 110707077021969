@import '../variables.scss';

.intro-text-ship-smart-military {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  width: 100%;

  .sign {
    width: 192px;
    height: 192px;
    background-repeat: no-repeat;
    background-size: contain;

    &.army {
      background-image: url('../assets/images/us-army.png');
    }

    &.marine {
      background-image: url('../assets/images/us-marine.png');
    }
  }

  h1 {
    font-weight: 400;
  }

  h1,
  h4 {
    text-align: center;
  }
}

@media (max-width: $dt) {
  .intro-text-ship-smart-military {
    row-gap: 24px;

    h1 {
      font-size: 36px;
    }

    h4 {
      font-size: 20px;
    }
  }
}
