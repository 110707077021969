@import '../variables.scss';

#availability-form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 550px;
  min-width: 280px;

  .block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .horizont {
    display: flex;
    column-gap: 16px;
    align-items: flex-end;
  }

  .width-50 {
    width: 50%;
  }

  .insurance-radio-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 16px;
  }

  .insurance-cost {
    position: relative;
    flex-grow: 1;
    flex-basis: 0;

    &.disabled {
      &::before {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &::before {
      position: absolute;
      content: '$';
      color: whitesmoke;
      bottom: 8px;
      left: 8px;
      font-size: 18px;
    }

    label {
      overflow-wrap: break-word;
    }

    input {
      padding-left: 20px;
    }
  }
}

@media (max-width: $dt) {
  #availability-form {
    width: unset;

    .horizont {
      flex-direction: column;
      row-gap: 8px;
    }

    .width-50 {
      width: 100%;
    }

    .insurance-cost {
      width: 100%;

      &::before {
        font-size: 16px;
        bottom: 9px;
      }
    }
  }
}
