@import '../variables.scss';

@keyframes appearing {
  0% {
    transform: scale(100%);
  }

  50% {
    transform: scale(200%);
  }
  100% {
    transform: scale(100%);
  }
}

.chat-button-wrap {
  position: fixed;
  right: 64px;
  bottom: 64px;
  z-index: 10;

  .chat-button {
    animation: appearing 1s 2 1s;
    border-radius: 50%;
    padding: 16px;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: $dt) {
  .chat-button-wrap {
    right: 32px;
    bottom: 32px;
  }
}
