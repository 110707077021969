@keyframes showingup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: none;

  &.show {
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: showingup;
    animation-duration: 300ms;
  }

  .modal-content-wrapper {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    overflow: hidden;

    .close-button-wrapper {
      position: absolute;
      top: 8px;
      right: 0px;
      opacity: 0.9;
      z-index: 1;

      .close-icon {
        width: 16px;
        height: 16px;
        background-image: url('../assets/images/close.png');
        background-size: contain;
      }
    }
  }

  .order-form-1-btn {
    position: relative;
  }
}
