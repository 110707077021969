@import '../variables.scss';

.order-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 1440px;
  position: relative;

  .top-items-wrap {
    position: sticky;
    top: 24px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  form {
    position: relative;
    z-index: 1;
  }
}

@media (max-width: $dt) {
  .order-wrapper {
    flex-direction: column-reverse;
    justify-content: flex-end;

    .top-items-wrap {
      position: relative;
      top: 0;
      align-items: center;
      padding: 8px 8px 0;
    }
  }
}
