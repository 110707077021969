@import '../variables.scss';

.payment-bank-form {
  width: 560px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .horizont {
    display: flex;
    align-items: flex-end;
    column-gap: 16px;
  }

  .account-type,
  .owner-type {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

@media (max-width: $dt) {
  .payment-bank-form {
    width: 300px;

    .horizont {
      &.no-mob {
        flex-direction: column;
        align-items: stretch;
        row-gap: 8px;
      }
    }

    .account-type,
    .owner-type {
      width: 100%;
    }
  }
}
