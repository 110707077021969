@import '../variables.scss';

.navigation {
  width: 100%;
  height: 100%;

  .container {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;

    &.no-auth {
      justify-content: center;
    }

    .logo-email-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      &.no-user {
        width: 100%;
        justify-content: space-between;
      }

      .logo {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 320px;
        height: 80px;
      }

      .email-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .email-icon {
          width: 18px;
          height: 18px;
          background-image: url('../assets//images//email.png');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    .user-block {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: flex-end;

      .username-wrapper {
        display: flex;
        column-gap: 8px;
        align-items: center;

        .user-icon {
          width: 18px;
          height: 18px;
          flex-shrink: 0;
          background-image: url('../assets/images/user-white.png');
          background-size: contain;
          background-repeat: no-repeat;
        }

        p {
          color: whitesmoke;
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: $dt) {
  .navigation {
    .nav-wrapper {
      .logo-email-wrapper {
        flex-direction: column;
        gap: 4px;

        .logo {
          width: 160px;
          height: 48px;
        }

        .email-wrapper {
          .email-icon {
            display: none;
          }
        }
      }

      .user-block {
        .username-wrapper {
          .user-icon {
            width: 14px;
            height: 14px;
          }

          p {
            font-size: 14px;
          }
        }
        a {
          font-size: 14px;
        }
      }
    }
  }
}
