@import '../variables.scss';

.order-form-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 500ms ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
  width: fit-content;
  border-radius: 8px;
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.5);

  &.no-transition {
    transition: none;
  }

  &.left {
    transform: translateX(calc(-100vw - 50%));
  }

  &.right {
    transform: translateX(calc(100vw - 50%));
  }

  .header-wrapper {
    width: 100%;
    padding: 32px 32px 0;
    text-align: center;

    .header {
      text-align: center;
    }
  }

  .form-wrapper {
    padding: 32px;
    width: 100%;

    > .buttons-wrapper {
      padding-top: 32px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: $dt) {
  .order-form-template {
    .header-wrapper {
      padding: 16px 16px 8px;
    }

    .form-wrapper {
      padding: 16px;

      > .buttons-wrapper {
        padding-top: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
