@import '../variables.scss';

.intro-text {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
  max-width: 640px;

  h1 {
    font-weight: 400;
  }

  h1,
  h4 {
    text-align: center;
  }
}

@media (max-width: $dt) {
  .intro-text {
    row-gap: 24px;

    .welcome {
      font-size: 36px;
    }

    h4 {
      font-size: 20px;
    }
  }
}
