.label-textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  label {
    display: block;

    &.error {
      color: red !important;
    }
  }

  textarea {
    flex-grow: 1;
  }

  &:focus-within {
    label {
      color: var(--primary-color);
    }
  }

  &.disabled {
    label {
      color: rgba(255, 255, 255, 0.5);
    }

    textarea {
      border: 1px solid rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.5);

      &::placeholder {
        color: whitesmoke;
      }
    }
  }
}
