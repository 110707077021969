@import '../variables.scss';

.chat-window {
  position: fixed;
  right: 64px;
  bottom: 64px;
  width: 400px;
  height: 480px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: black;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms;
  z-index: 10;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .quote-num {
    align-self: center;
  }

  .close-button {
    position: absolute;
    padding: 16px;
    top: 0;
    right: 0;

    .content-wrapper {
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}

@media (max-width: $dt) {
  .chat-window {
    width: 90vw;
    max-width: 400px;
    right: 5vw;
    bottom: 32px;
  }
}
