@import '../variables.scss';

.container {
  position: relative;
  margin: auto;
  max-width: 1280px;
  padding: 24px;
  min-height: 100%;
}

@media (max-width: $dt) {
  .container {
    padding: 8px;
  }
}
