@import '../variables.scss';

.page-template {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .page-header-wrap {
    height: 96px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  .page-content {
    flex-grow: 1;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: scroll;
    overflow-x: hidden;

    &.home-page {
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.with-footer {
      margin-bottom: 64px;
    }
  }
}

@media (max-width: $dt) {
  .page-template {
    .page-header-wrap {
      height: 80px;
    }

    .page-content {
      &.home-page {
        .container {
          padding-top: 24px;
          padding-bottom: 24px;
        }
      }
    }
  }
}
