.details-block {
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;

    .icon-button-wrapper {
      visibility: hidden;

      &.edit {
        visibility: visible;
      }
    }
  }

  .info-wrapper {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
