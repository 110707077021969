@import '../variables.scss';

.input-zip-country {
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  display: flex;
  column-gap: 32px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;

  &:focus-within {
    border: 1px solid var(--primary-color);

    label {
      color: var(--primary-color);
    }
  }

  label {
    position: absolute;
    top: -26px;
    padding: 0 4px;
    background-color: black;
    white-space: nowrap;

    &.error {
      color: red;
    }
  }

  input {
    border: none;
    padding: 0;
    max-width: 235px;
  }

  .zip,
  .country {
    position: relative;
  }

  .country {
    label {
      right: 0;
    }
  }
}
