.payments {
  .form-template-content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 16px 0;

    .payment-method-radio {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }

  .payment-status-msg {
    white-space: pre-line;
    text-align: center;
  }
}
