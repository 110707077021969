@import '../variables.scss';

.payment-card-form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .horizont {
    display: flex;
    align-items: flex-end;
    column-gap: 16px;
  }

  .full-name-input {
    flex-grow: 1;
  }

  .zip-input {
    max-width: 160px;
  }

  .card-number-input {
    max-width: 240px;
  }

  .cvv-input {
    max-width: 80px;
  }

  .year-slash-month {
    display: flex;
    column-gap: 8px;
    align-items: flex-end;

    .year-input {
      max-width: 60px;

      label {
        width: 140px;
      }
    }

    .month-input {
      max-width: 60px;
    }

    .slash {
      transform: translateY(-10px);
    }
  }
}

@media (max-width: $dt) {
  .payment-card-form {
    max-width: 300px;

    .mob-wrap {
      flex-wrap: wrap;
      row-gap: 8px;
      justify-content: space-between;
    }

    .zip-input {
      max-width: 80px;
    }

    .card-number-input {
      max-width: unset;
      flex-grow: 1;
    }
  }
}
