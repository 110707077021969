@import '../variables.scss';

.signin-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  > .button {
    width: 100%;
    max-width: 380px;
    border: 1px solid gray;

    .content-wrapper {
      column-gap: 32px;

      .btn-text {
        flex-grow: 1;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: $dt) {
  .signin-panel {
    > .button {
      max-width: 320px;

      .content-wrapper {
        .btn-text {
          font-size: 18px;
        }
      }
    }
  }
}
