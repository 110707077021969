@import '../variables.scss';

#hauler-info {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  min-width: 280px;

  .horizont {
    display: flex;
    column-gap: 16px;
  }

  .width-50 {
    width: 50%;
  }
}

@media (max-width: $dt) {
  #hauler-info {
    .horizont {
      &:not(.all-devices) {
        flex-direction: column;
        row-gap: 8px;
      }
    }
  }
}
