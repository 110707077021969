@import '../variables.scss';

.intro-text-muva-freight {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;

  h1,
  h3 {
    text-align: center;
    font-weight: 400;
  }

  h1 {
    font-size: 56px;
  }
}

@media (max-width: $dt) {
  .intro-text-muva-freight {
    row-gap: 24px;

    h1,
    h3 {
      font-weight: 600;
      line-height: 1.4;
    }

    h1 {
      font-size: 28px;
    }

    h3 {
      font-size: 18px;
    }
  }
}
