@import '../variables.scss';

.label-date {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  label {
    display: block;

    &.error {
      color: red;
    }
  }

  .date-picker-wrap {
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 8px;

    .react-date-picker {
      width: fit-content;
      display: block;
      transition: opacity 500ms;
      margin: 0 auto;

      &.react-date-picker--disabled {
        opacity: 0.5;
        color: unset;
        background-color: unset;
      }

      .react-calendar {
        background: black;
        border-radius: 8px;

        .react-calendar__navigation {
          button {
            color: whitesmoke;

            &:hover {
              background-color: rgba(255, 255, 255, 0.2);
            }

            &:disabled {
              background-color: unset;
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }

        .react-calendar__month-view__days__day {
          color: whitesmoke;

          &.react-calendar__month-view__days__day--weekend {
            color: red;
          }

          &.react-calendar__month-view__days__day--neighboringMonth {
            color: rgba(255, 255, 255, 0.5);
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }

        .react-calendar__tile {
          &:disabled {
            background-color: unset;
            color: rgba(255, 255, 255, 0.5);
          }

          &.react-calendar__tile--now {
            &:not(:disabled) {
              color: #ffff76;
              &:not(:hover) {
                &:not(.react-calendar__tile--active) {
                  background: unset;
                }
              }
            }
          }
        }
      }

      .react-date-picker__wrapper {
        border: none;

        .react-date-picker__inputGroup__year {
          min-width: 48px;
        }

        button {
          outline: none;
        }

        .calendar-icon {
          width: 16px;
          height: 16px;
          background-image: url('../assets/images/calendar_icon.png');
          background-size: contain;
          background-repeat: none;
          opacity: 0.8;
        }
      }

      .react-date-picker__inputGroup__month {
        min-width: 56px;
      }
    }
  }
}
