.preview-wrapper {
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
  max-width: 480px;

  .preview {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  video {
    width: 80px;
    height: 80px;
  }
}
