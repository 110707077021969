.label-time {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  label {
    display: block;
  }

  .select-wrap {
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 8px;

    select {
      display: block;
      margin: 0 auto;
    }
  }
}
