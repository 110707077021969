.order-form-2-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .flag-input-wrapper {
    display: flex;
    column-gap: 16px;

    .phone-input {
      flex-grow: 1;
    }
  }
}
