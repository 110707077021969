@import '../variables.scss';

.order-form-1-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 500ms ease-in-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 24px;
  padding: 32px;
  border-radius: 8px;
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.5);

  &.left {
    transform: translateX(calc(-100vw - 50%));
  }

  &.right {
    transform: translateX(calc(100vw - 50%));
  }

  &.no-margin-top {
    margin-top: 0;
  }

  .header {
    text-align: center;
  }

  > .children-wrapper {
    position: relative;
    width: 100%;
    padding: 16px 0;
  }

  .under-btn-el-wrapper {
    padding-top: 24px;
  }
}

@media (max-width: $dt) {
  .order-form-1-btn {
    .inner-wrapper {
      padding: 16px;
    }
  }
}
