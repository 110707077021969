@import '../variables.scss';

.upload-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
  min-height: 120px;
  position: relative;
  min-width: 300px;

  .go-to-mobile {
    text-align: center;
  }

  label {
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    text-align: center;
    transition: opacity 300ms;

    &.invisible {
      opacity: 0;
    }

    &.error {
      color: red;
      opacity: 1;
    }
  }

  .files-wrapper {
    position: relative;
    width: 100%;
  }

  input {
    display: none;
  }

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media (max-width: $dt) {
  .upload-video {
    .go-to-mobile {
      display: none;
    }

    label {
      top: 0;
    }
  }
}
