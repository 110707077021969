.order-form-1 {
  min-width: 260px;

  .radio-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-left: 16px;

    .radio-i-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
