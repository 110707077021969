.label-input {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  label {
    display: block;

    &.error {
      color: red !important;
    }
  }

  &:focus-within {
    label {
      color: var(--primary-color);
    }
  }

  input[type='password'] {
    padding-right: 32px;
  }

  .show-pwd-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  &.disabled {
    label {
      color: rgba(255, 255, 255, 0.5);
    }

    input {
      border: 1px solid rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.5);

      &::placeholder {
        color: whitesmoke;
      }
    }
  }
}
