.chat-input {
  border: 1px solid gray;
  border-radius: 8px;
  width: 100%;
  padding: 4px 8px;
  display: flex;

  &:focus-within {
    border: 1px solid var(--primary-color);
  }

  input {
    flex-grow: 1;
    padding: 0;
    border: none;
  }

  .send-button {
    padding: 8px;
  }
}
