.order-form-4-5-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-width: 520px;
  min-width: 300px;

  .row1,
  .row2,
  .row3,
  .row4 {
    display: flex;
    column-gap: 16px;
    align-items: last baseline;
  }

  .row1 {
    & > :first-child {
      width: 80%;
    }
    & > :last-child {
      width: 20%;
    }
  }

  .row2,
  .row3,
  .row4 {
    & > :first-child {
      width: 50%;
    }
    & > :last-child {
      width: 50%;
    }
  }

  .row4 {
    &.freight {
      & > :last-child {
        width: 100%;
      }
    }

    .parking-label-select,
    .bedrooms-label-select {
      select {
        option {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  .row5 {
    display: none;

    &.show {
      display: block;
    }
  }
}
