@import '../variables.scss';

.video-recorder {
  z-index: 20;

  .modal-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background: black;
    padding: 48px 16px 16px;

    .video-frame {
      position: relative;

      video {
        border-radius: 8px;
        overflow: hidden;
      }
    }

    .horizontal-warning {
      visibility: hidden;
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      gap: 64px;
      align-items: center;
      justify-content: center;

      .please-hold {
        text-align: center;
      }

      .how-to-hold-camera {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .phone-vert,
        .phone-hor,
        .cross-circle,
        .check-circle {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .do,
        .dont {
          display: flex;
          align-items: center;

          p {
            width: 20%;
            flex-shrink: 0;
          }
        }

        .phone-wrap {
          display: flex;
          flex-grow: 1;
          justify-content: center;

          .phone-vert,
          .phone-hor {
            width: 64px;
            height: 64px;
          }
        }

        .cross-circle,
        .check-circle {
          width: 32px;
          height: 32px;
        }

        .dont {
          .phone-vert {
            background-image: url('../assets/images/phone-vert.png');
          }

          .cross-circle {
            background-image: url('../assets/images/cross-circle.png');
          }
        }

        .do {
          .phone-hor {
            background-image: url('../assets/images/phone-hor.png');
          }

          .check-circle {
            background-image: url('../assets/images/check-circle.png');
          }
        }
      }
    }

    .start-stop-btns-wrapper {
      position: relative;
      height: 56px;
      width: 180px;

      .start-button,
      .stop-button {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        visibility: hidden;
        opacity: 0;
        transition: all 300ms;

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .camera-button,
    .allow-msg,
    .err-msg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      opacity: 0;
      transition: all 300ms;

      &.show {
        visibility: visible;
        opacity: 1;
      }
    }

    .allow-msg,
    .err-msg {
      width: 100%;
      text-align: center;
    }

    .err-msg {
      top: 20%;
      color: red;
    }
  }
}

@media screen and (orientation: portrait) and (max-width: $dt) {
  .video-recorder {
    .modal-content-wrapper {
      .horizontal-warning {
        &.show {
          visibility: visible;
        }
      }

      .camera-button {
        &.show {
          visibility: hidden;
        }
      }
    }
  }
}

@media screen and (orientation: landscape) and (max-width: $dt) {
  .video-recorder {
    .modal-content-wrapper {
      flex-direction: row;
    }
  }
}
