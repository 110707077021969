@import '../variables.scss';

.checkbox {
  display: flex;
  align-items: center;
  column-gap: 8px;

  label {
    font-size: 18px;
    color: whitesmoke;
  }
}

@media (max-width: $dt) {
  .checkbox {
    label {
      font-size: 16px;
    }
  }
}
