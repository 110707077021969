.info-block {
  position: relative;
  width: 16px;
  height: 16px;
  background-image: url('../assets/images/info.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    .bubble {
      visibility: visible;
      opacity: 1;
    }
  }
}
