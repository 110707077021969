@import '../variables.scss';

.order-form-3-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 360px;
  max-width: 440px;

  .horizontal {
    display: flex;
    column-gap: 16px;
    row-gap: 24px;

    .packing-block-wrapper {
      width: 60%;
    }

    .storage-block-wrapper {
      width: 40%;
    }

    .packing-block-wrapper,
    .storage-block-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .radio-wrapper {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .radio-i-wrap {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  .requirements {
    height: 120px;
  }
}

.modal-short-time-warn {
  .modal-content-wrapper {
    padding: 48px 32px 32px;
    background-color: black;
    width: 480px;

    p {
      text-align: justify;
    }

    .btn-wrapper {
      padding-top: 32px;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: $dt) {
  .order-form-3-wrapper {
    min-width: 280px;

    .horizontal {
      flex-direction: column-reverse;

      .packing-block-wrapper,
      .storage-block-wrapper {
        width: unset;
      }
    }
  }

  .modal-short-time-warn {
    .modal-content-wrapper {
      padding: 48px 16px 16px;
      width: 340px;

      .btn-wrapper {
        padding-top: 24px;
      }
    }
  }
}
