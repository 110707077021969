@import '../variables.scss';

.outro-text-freight-hauler {
  color: whitesmoke;
  font-weight: 400;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border: 1px solid whitesmoke;
  padding: 16px 24px;
  max-width: 80%;
  white-space: pre-line;
}

@media (max-width: $dt) {
  .outro-text-freight-hauler {
    padding: 12px 24px;
    margin-top: 32px;
  }
}
