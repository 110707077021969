.chat-message {
  display: flex;
  column-gap: 8px;
  align-items: center;
  overflow-wrap: anywhere;

  &.user {
    flex-direction: row-reverse;

    .message-bubble {
      background-color: var(--primary-color);
      p {
        color: whitesmoke;
      }
    }
  }

  &.not-user {
    .message-bubble {
      background-color: whitesmoke;
      color: black;
    }
  }

  .message-bubble {
    padding: 4px 8px;
    border-radius: 8px;
  }

  .time {
    opacity: 0;
    transition: all 300ms;
    flex-shrink: 0;

    &.show {
      opacity: 1;
    }

    label {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
