@import '../variables.scss';

.progress-bar {
  opacity: 1;
  transition: 300ms;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  &.invisible {
    opacity: 0;
  }

  .chart-container {
    width: 144px;
    height: 144px;
    position: relative;

    .chart-segment,
    .circle {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 20px solid rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      transition: 500ms;
    }

    .circle {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }

  .step-text {
    position: absolute;
  }
}

@media (max-width: $dt) {
  .progress-bar {
    .chart-container {
      width: 136px;
      height: 136px;
    }
  }
}
