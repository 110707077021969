@import '../variables.scss';

.add-video {
  .upload-video-wrapper {
    padding-bottom: 16px;
    min-width: 340px;
  }
}

@media (max-width: $dt) {
  .add-video {
    .upload-video-wrapper {
      padding-top: 16px;
      padding-bottom: 0;
      min-width: unset;

      .upload-video {
        .buttons-wrapper {
          column-gap: 16px;
        }
      }
    }
  }
}
