@import '../variables.scss';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  label {
    color: white;
  }

  .logo {
    background-image: url('../assets/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 64px;
    height: 24px;
    transform: translateY(-4px);
  }
}
