@import '../variables.scss';

.intro-text-american-dream {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  width: 100%;

  h1 {
    font-weight: 400;
  }

  h1,
  h4 {
    text-align: center;
  }

  .veteran-owned {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    gap: 8px;
    border-top: 1px white solid;
    border-bottom: 1px white solid;
    padding: 16px 0;

    p {
      text-align: center;
    }
  }
}

@media (max-width: $dt) {
  .intro-text-american-dream {
    row-gap: 24px;

    h4 {
      font-size: 20px;
    }

    .welcome {
      font-size: 36px;
    }

    .veteran-owned {
      padding: 8px 0;
    }
  }
}
