.cancel-confirmation {
  .children-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .header {
      text-align: center;
    }

    .radio-box {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    .label-input {
      opacity: 0;
      visibility: hidden;
      transition: all 300ms;

      &.show {
        visibility: visible;
        opacity: 1;
      }
    }

    .error-label {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      color: red;
      text-align: center;
      opacity: 0;
      visibility: hidden;
      transition: all 300ms;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
