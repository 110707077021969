.label-select {
  label {
    display: block;

    &.error {
      color: red !important;
    }
  }

  select {
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 8px;
    margin: 8px 0;
    width: 100%;
  }
}
