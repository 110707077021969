@import '../variables.scss';

.home {
  position: relative;

  .home-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 96px;
  }
}

@media (max-width: $dt) {
  .home {
    .home-content {
      row-gap: 32px;
    }
  }
}
