.pending-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 24px 0;
  min-width: 280px;

  h3 {
    text-decoration: underline;
  }

  label {
    display: block;
    text-align: center;
  }

  .clapping-hands-block {
    max-width: 400px;

    .clap-icon {
      width: 96px;
      height: 96px;
      margin: 0 auto 48px;
      background-image: url('../assets/images/clap-icon.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
