@import '../variables.scss';

.quotes-list {
  margin: 16px 0;

  .header {
    margin-bottom: 8px;
  }

  .header,
  .string {
    display: flex;
    align-items: center;

    .num-date {
      display: flex;

      .column-1 {
        width: 200px;
      }

      .column-2 {
        width: 160px;
      }
    }

    .column-3 {
      width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .record-icon {
        width: 24px;
        height: 24px;
        background-image: url('../assets/images/video.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    p,
    h6 {
      flex-grow: 1;
      text-align: center;
    }
  }

  .quotes-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-height: 180px;
    overflow-y: scroll;

    .string {
      cursor: pointer;
    }
  }

  .dt {
    display: block;
  }

  .mobile {
    display: none;
  }
}

@media (max-width: $dt) {
  .quotes-list {
    .header,
    .string {
      .num-date {
        flex-direction: column;

        .column-1 {
          width: 168px;
        }

        .column-2 {
          width: 168px;
        }
      }
    }

    .dt {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
}
