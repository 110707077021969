.content-wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;

  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .children-wrapper {
    flex-grow: 1;
  }
}
