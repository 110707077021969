.details {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .error-label {
    color: red;
    text-align: center;
    opacity: 0;
    transition: opacity 300ms;

    &.show {
      opacity: 1;
    }
  }
}
