.login-page {
  .login-page-content {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    h3 {
      text-align: center;
    }
  }
}
