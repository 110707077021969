.chat-dialogue {
  flex-grow: 1;
  border: 1px solid gray;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  .scrolling-wrap {
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow: scroll;

    .scrolling-area {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 8px;

      .date {
        text-align: center;
        padding-bottom: 8px;
      }

      .name {
        padding-bottom: 4px;

        &.user {
          text-align: right;
        }
      }
    }
  }
}
