.order-form-0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 280px;

  .from,
  .to {
    margin: 16px 0;

    p {
      width: fit-content;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 700;
      color: whitesmoke;
    }
  }

  button {
    position: absolute;
    bottom: -160px;
  }
}
