@import '../variables.scss';

.label-text {
  display: flex;
  align-items: center;
  column-gap: 16px;

  label {
    flex-grow: 1;
  }

  p {
    width: 320px;
    word-wrap: anywhere;
  }
}

@media (max-width: $dt) {
  .label-text {
    column-gap: 8px;

    p {
      width: 192px;
      min-width: 192px;
    }
  }
}
