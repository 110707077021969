.uploading-progress-bar {
  width: 100%;
  height: 32px;
  max-height: 0;
  position: relative;
  overflow: hidden;
  transition: 300ms;

  &.show {
    max-height: 32px;
  }

  .progress {
    height: 100%;
    border-radius: 8px;
    background-color: var(--primary-color);
  }

  .percent-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .percent {
      font-weight: 600;
    }
  }
}
